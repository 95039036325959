import { useEffect, useState } from 'react';

const useOptimizely = () => {
  const [optimizelyReady, setOptimizelyReady] = useState(false);
  const [variationId, setVariationId] = useState<string | undefined>(undefined);
  const [optimizelyExperiment, setOptimizelyExperiment] = useState<
    string | undefined
  >('');

  const getActiveVariationId = () => {
    const optimizely = window.optimizely as Optimizely;

    if (optimizely && optimizely.initialized) {
      const [activeExperimentId] = optimizely
        .get('state')
        .getActiveExperimentIds();

      if (activeExperimentId) {
        const variationData = optimizely.get('state').getVariationMap()[
          activeExperimentId
        ];

        if (
          variationData &&
          variationData.name.toLowerCase().includes('variation')
        ) {
          return variationData.id;
        }
      }
    }

    return undefined;
  };

  const onInitialized = () => {
    setOptimizelyReady(true);
    setOptimizelyExperiment(window.optExperiment);
    setVariationId(getActiveVariationId());
  };

  useEffect(() => {
    if (window) {
      window.optimizely = window.optimizely || [];

      if (window.optimizely instanceof Array) {
        window.optimizely.push({
          type: 'addListener',
          filter: {
            type: 'lifecycle',
            name: 'initialized',
          },
          handler: onInitialized,
        });
      } else if (window.optimizely.initialized) {
        onInitialized();
      }
    }
  }, []);

  return {
    optimizelyReady,
    optimizelyExperiment,
    variationId,
  };
};

export default useOptimizely;
