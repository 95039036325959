import {
  ISbRichtext,
  renderRichText,
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import parse from 'html-react-parser';

import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import benefitHeadingRichTextSchema from './richTextSchema';

export interface BenefitHeadingRichtextBlokProps extends SbBlokData {
  actions: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  content?: ISbRichtext;
  header?: ISbRichtext;
  topic?: string;
}

interface BenefitHeadingRichtextProps {
  blok: BenefitHeadingRichtextBlokProps;
  parentLayout: 'imageAndHeading' | 'imageAndHeadingList' | 'headingList';
  parentLayoutDirection?: 'default' | 'reversed';
  isCard?: boolean;
  hasTopPaddingOnMobile?: boolean;
  textCenteredOnMobile?: boolean;
  backgroundColor?:
    | 'none'
    | 'GSparchment/100'
    | 'GSpurple/100'
    | 'GSgreen/100'
    | 'GSyellow/100';
  textAlignment?: 'left' | 'center';
  headingsLevel: 'h2' | 'h3';
}

const BenefitHeadingRichtext = ({
  backgroundColor = 'none',
  blok,
  isCard = false,
  parentLayout,
  parentLayoutDirection,
  hasTopPaddingOnMobile = true,
  textCenteredOnMobile = false,
  textAlignment = 'left',
  headingsLevel = 'h3',
}: BenefitHeadingRichtextProps) => {
  const { actions = [], content, header, topic } = blok;

  const HeadingLevel = headingsLevel;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('rounded-lg gap-2 lg:gap-6 flex flex-col', {
        'justify-center': parentLayout === 'imageAndHeading',
        'bg-none': backgroundColor === 'none' || !backgroundColor,
        'bg-GSpurple/100': backgroundColor === 'GSpurple/100',
        'bg-GSgreen/100': backgroundColor === 'GSgreen/100',
        'bg-GSyellow/100': backgroundColor === 'GSyellow/100',
        'bg-GSparchment/100': backgroundColor === 'GSparchment/100',
        'text-center': textAlignment === 'center',
        'text-left': textAlignment === 'left',
        'text-center sm:text-left': textCenteredOnMobile,
        'py-4 sm:py-6 lg:py-10':
          isCard ||
          (backgroundColor !== 'none' && parentLayout !== 'imageAndHeading'),
        'px-4 sm:px-6 lg:px-10':
          isCard || (backgroundColor && backgroundColor !== 'none'),
        '!pt-0 sm:!pt-8': hasTopPaddingOnMobile === false,
        'sm:pl-8':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'reversed' &&
          isCard,
        'sm:pr-8':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'default' &&
          isCard,
        'sm:mx-4 lg:mx-0 lg:mr-[42px]':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'reversed',
        'sm:mx-4 lg:mx-0 lg:ml-[42px]':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'default',
      })}
    >
      <div className="flex flex-col w-full gap-2">
        {topic && (
          <div className="font-medium text-xs leading-6 lg:text-sm lg:leading-6 text-GSsteel/500 tracking-[2px]">
            {topic}
          </div>
        )}
        {header && (
          <HeadingLevel
            className={cx(
              'font-raleway font-bold text-[28px] leading-10 lg:text-[40px] lg:leading-[56px]',
            )}
          >
            {parse(
              renderRichText(header, {
                schema: benefitHeadingRichTextSchema,
              }),
            )}
          </HeadingLevel>
        )}
      </div>
      <div className="flex flex-col gap-6 font-worksans text-lg">
        {content &&
          parse(
            renderRichText(content, {
              schema: benefitHeadingRichTextSchema,
            }),
          )}
        {actions && actions.length > 0 && (
          <div className="hidden sm:flex gap-2 flex-col sm:flex-row">
            {actions.map((nestedBlock) => (
              <StoryblokComponent blok={nestedBlock} key={nestedBlock._uid} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BenefitHeadingRichtext;
