import { LazyMotion, m } from 'framer-motion';

const loadFeatures = () =>
  import('../../helpers/framerFeatures').then((res) => res.default);

const AnimatedContainer = ({
  animate,
  children,
  className,
}: {
  animate?: boolean;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  if (animate) {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          initial={{
            opacity: 0,
            y: 100,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
            },
          }}
          viewport={{ once: true }}
          className={className || ''}
        >
          {children}
        </m.div>
      </LazyMotion>
    );
  }

  return <div className={className || ''}>{children}</div>;
};

export default AnimatedContainer;
