import { getStoryblokApi, SbBlokData } from '@storyblok/react';
import * as yup from 'yup';
import { nanoid } from 'nanoid';
import { getCookie, setCookie } from 'cookies-next';
import { FormBlokProps } from '@/blocks/Form/FormType';
import { FormUserTypeFieldBlokProps } from '@/blocks/FormUserTypeField/FormUserTypeField';
import { getMultiStepFormFields } from '@/blocks/Form/formFieldsHelpers';
import {
  FormDataType,
  MatchesParams,
  StringValidation,
  Validation,
  ValidationSchema,
} from './formHelpersTypes';
import { FormTextDataStructure } from '../datasources/fetchDatasources';
import { getFieldData } from './formFields';
import { getPrefixedNationalNumber, isPhoneValid } from './phoneHelpers';

export const REQUIRED_FIELDS = ['phone', 'email', 'firstName'];

yup.addMethod(
  yup.StringSchema,
  'phoneNumberValid',
  function phoneNumberValid(...params) {
    const [errorMessage] = params;

    // eslint-disable-next-line func-names
    return this.test('phone-number-valid', errorMessage, function (value) {
      const { path, createError } = this;

      if (value && isPhoneValid(value)) {
        return true;
      }

      return createError({ path, message: errorMessage });
    });
  },
);

export const PROD_SUBMISSION_SERVICE_ENDPOINT =
  'https://submission.gostudent.org/v1/customer-lead';

function createYupSchema(schema: ValidationSchema, config: FormDataType) {
  const newSchema = schema;
  const { id, validationType, validations = [] } = config;
  if (!validationType || !validations) {
    return newSchema;
  }
  if (!yup[validationType]) {
    return newSchema;
  }

  let validator: ValidationSchema['key'];

  if (validationType === 'mixed') {
    validator = yup.mixed().transform((value) => {
      if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0 ? undefined : value;
      }
      return value;
    });
  } else {
    validator = yup[validationType]();
  }

  if (Array.isArray(validations)) {
    validations.forEach((validation) => {
      if (validator instanceof yup.StringSchema) {
        const { params, type } = validation as StringValidation;

        if (!validator[type]) {
          return;
        }
        if (type === 'matches') {
          validator = validator[type](...(params as MatchesParams));
        } else {
          validator = validator[type](...(params as Array<string>));
        }
      } else {
        const { params, type } = validation as Validation;

        if (!validator[type]) {
          return;
        }
        validator = validator[type](...params);
      }
    });
  }
  newSchema[id] = validator;
  return newSchema;
}

const getFieldValidationSchema = (
  field: SbBlokData,
  labels: FormTextDataStructure,
) => {
  const { component: name, required: isRequired } = field;

  const result: FormDataType = {
    hubspotId: getFieldData(name).hubspotId,
    id: getFieldData(name).id,
    type: getFieldData(name).type,
    validationType: getFieldData(name).validationType,
    value: '',
    validations: [],
  };

  if (isRequired) {
    if (result.type === 'boolean') {
      result.validations?.push({
        key: 'general_error_message_checked',
        params: [
          labels.general_error_message_checked ||
            'general_error_message_checked is missing in Datasources!',
        ],
        type: 'isTrue' as const,
      });
    } else {
      result.validations?.push({
        key: 'general_error_message_required',
        params: [
          labels[`${result.id}_error_message_required`] ||
            labels.general_error_message_required ||
            `${result.id}_error_message_required is missing in Datasources!`,
        ],
        type: 'required' as const,
      });
    }
  }

  result.validations?.push(
    ...(getFieldData(name).validations?.map((validation) => {
      if (validation.type === 'matches') {
        return {
          ...validation,
          params: [
            validation.params[0],
            labels[validation.key] ||
              `${validation.key} is missing in Datasources!`,
          ] as MatchesParams,
        };
      }

      if (validation.type === 'phoneNumberValid') {
        return {
          ...validation,
          params: [
            labels[validation.key] ||
              `${validation.key} is missing in Datasources!`,
          ],
        };
      }

      return validation;
    }) || []),
  );

  return result;
};

export const getFieldsValidationSchema = (
  fields: Array<SbBlokData>,
  labels: FormTextDataStructure,
) => {
  const fieldsValidations: Array<FormDataType> =
    fields
      .map((field) => getFieldValidationSchema(field, labels))
      .filter((item) => (item.validations || []).length > 0) || [];

  const yepSchema = fieldsValidations.reduce(createYupSchema, {});
  return yup.object().shape(yepSchema);
};

const getFieldInitialValue = (
  fieldBlok: SbBlokData,
  fillParams?: (text: string) => string | Element,
) => {
  if (getFieldData(fieldBlok.component).type === 'array') {
    return fieldBlok.initialValue?.toString().split(',').filter(Boolean) || [];
  }

  if (getFieldData(fieldBlok.component).type === 'boolean') {
    return (
      fieldBlok.initialValue === 'checked' || fieldBlok.initialValue === true
    );
  }

  if (getFieldData(fieldBlok.component).type === 'text' && fillParams) {
    return fillParams(fieldBlok.initialValue as string);
  }

  return fieldBlok.initialValue || '';
};

export function getInitialValues(
  blok?: FormBlokProps,
  extras?: { [key: string]: string | boolean | number | object | undefined },
  fillParams?: (text: string | undefined) => string,
) {
  const initialValues: { [key: string]: string | boolean | number | object } =
    {};

  initialValues.language = blok?.language || 'none-set';
  initialValues.userType = blok?.userType || 'none-set';
  initialValues.experiment =
    extras?.optimizelyExperiment || blok?.experiment || 'none-set';
  initialValues.reachedOutVia = blok?.reachedOutVia || '';
  initialValues.referralID = extras?.referralID || '';
  if (extras?.referralTeam) {
    initialValues.referralTeam = extras.referralTeam;
  }
  if (extras?.recommendedTutorID) {
    initialValues.recommendedTutorID = extras?.recommendedTutorID;
  }
  initialValues.customSessionID = extras?.customSessionID || '';
  initialValues.lastSeen = extras?.lastSeen || '';
  initialValues.fullReferringPageUrl = extras?.fullReferringPageUrl || '';
  initialValues.analyticalId = 'none-set';
  initialValues.googleClientId = 'none-set';
  initialValues.emailConsentNewsletter = false;
  initialValues.termsAndConditions = false;
  initialValues.firstName = '';
  initialValues.email = '';
  initialValues.phone = '';

  blok?.fields
    ?.filter((field) => !field.excluded)
    .forEach((innerBlok) => {
      initialValues[getFieldData(innerBlok.component).id] =
        getFieldInitialValue(innerBlok, fillParams);
    });

  const stepFieldsInitialValues = blok
    ? getMultiStepFormFields(blok)
        .filter((field) => !field.excluded)
        .forEach((field) => {
          initialValues[getFieldData(field.component).id] =
            getFieldInitialValue(field, fillParams);
        })
    : [];

  return { ...initialValues, ...stepFieldsInitialValues };
}

export function getGoogleClientId() {
  const gaClientId = getCookie('_ga');

  // @ts-ignore noImplicitAny
  if (window.Cookiebot?.consent?.marketing === false) {
    return 'GA1 id not sent, marketing cookies have been declined';
  }
  if (!gaClientId) {
    return 'GA1 id not sent, cookie does not exist';
  }
  return (gaClientId as string) || '';
}

export function getAnalyticalId() {
  const userAnalyticalId = getCookie('analytical_id');

  if (!userAnalyticalId) {
    const newAnalyticalId = nanoid();
    setCookie('analytical_id', newAnalyticalId);

    return newAnalyticalId;
  }
  return userAnalyticalId as string;
}

export const getExistingFieldsIds = (blok: FormBlokProps) => {
  const deprecatedFieldIds =
    blok.fields
      ?.filter((field) => !field.excluded)
      .map(({ component }) => getFieldData(component).id) || [];

  if (blok.firstName?.length) {
    deprecatedFieldIds.push('firstName');
  }

  if (blok.email?.length) {
    deprecatedFieldIds.push('email');
  }

  if (blok.phone?.length) {
    deprecatedFieldIds.push('phone');
  }

  if (blok.newsletter?.length) {
    deprecatedFieldIds.push('emailConsentNewsletter');
  }

  if (blok.termsAndConditions?.length) {
    deprecatedFieldIds.push('termsAndConditions');
  }

  const stepsFieldsIds = getMultiStepFormFields(blok)
    .filter((field) => !field.excluded)
    .map(({ component }) => getFieldData(component).id);

  return [...deprecatedFieldIds, ...stepsFieldsIds];
};

const formatSubmittedData = (values: {
  [key: string]: string | boolean | number | object;
}) => {
  const result = { ...values };

  if (result.phone) {
    result.phone = getPrefixedNationalNumber(values.phone as string);
  }

  if (result.certifiedTeacher) {
    // this is radio which gives string and submission service expect boolean
    result.certifiedTeacher = result.certifiedTeacher === 'true';
  }

  return result;
};

export const submitForm = async (values: {
  [key: string]: string | boolean | number | object;
}) => {
  const url =
    process.env.submissionServiceEndpoint || PROD_SUBMISSION_SERVICE_ENDPOINT;

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formatSubmittedData(values)),
  };

  const request = await fetch(url, requestOptions);

  return request;
};

export const buildThankYouPageUrl = async (link: Link | undefined) => {
  if (!link) {
    return '';
  }

  if (link.linktype === 'story') {
    if (link.cached_url.startsWith('landing-pages')) {
      const slug = link.cached_url;

      return `${window.location.origin}/${slug.split('/').slice(1).join('/')}/`;
    }

    if (link.cached_url.includes('website/default')) {
      const storyblokApi = getStoryblokApi();
      const [langCountry, ...linkSlugSplitted] = link.cached_url
        .split('/')
        .filter(Boolean);

      const { data }: LinksDataResult = await storyblokApi.get('cdn/links/', {
        starts_with: linkSlugSplitted.join('/'),
        token: process.env.storyblokPublicAccessToken,
        version: 'published',
      });

      const linkData = Object.values(data.links).find(
        (item) => item.slug === link.cached_url.split('/').slice(2).join('/'),
      );

      const alternate = linkData?.alternates.find((item) =>
        item.lang.startsWith(langCountry),
      );

      if (alternate) {
        const targetSlug = [
          alternate.lang,
          ...alternate.translated_slug
            .split('website/default/')
            .filter(Boolean),
        ]
          .join('/')
          .split('/')
          .filter(Boolean)
          .join('/');

        return `${window.location.origin}/${targetSlug}`;
      }
    }
  }

  return link.url || link.cached_url;
};

export const isRedirectForm = (blok: FormBlokProps) =>
  blok.steps?.find(
    ({ redirectButton: [redirectButton] = [] }) => redirectButton,
  ) !== undefined;

export const getFormUserType = (formBlok: FormBlokProps) => {
  if (formBlok.type === 'multiStep') {
    const fields = getMultiStepFormFields(formBlok);
    const foundUserTypeField = fields?.filter(
      ({ component }) => getFieldData(component).id === 'userType',
    );

    if (foundUserTypeField.length > 1) {
      throw new Error('More than one User Type field found!');
    }

    if (foundUserTypeField.length === 1) {
      return (foundUserTypeField[0] as FormUserTypeFieldBlokProps).initialValue;
    }
  }

  if (
    formBlok.type === 'singleStep' ||
    formBlok.type === '' ||
    !formBlok.type
  ) {
    const foundUserTypeField = formBlok.fields?.find(
      ({ component }) => getFieldData(component).id === 'userType',
    );

    return formBlok.userType || foundUserTypeField?.initialValue;
  }
};
