import { SbBlokData, StoryblokComponent } from '@storyblok/react';

export interface AbTestVariationBlokProps extends SbBlokData {
  content: Array<SbBlokData>;
  id: string;
}

interface AbTestVariationProps {
  blok: AbTestVariationBlokProps;
}

const AbTestVariation = ({ blok }: AbTestVariationProps) => {
  return (
    <>
      {blok.content?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </>
  );
};

export default AbTestVariation;
