import { useContext } from 'react';
import { SbBlokData } from '@storyblok/react';

import ButtonComponent from '../../components/Button/Button';
import { SSGDataContext } from '../../helpers/contexts';

export interface LanguageBannerItemBlokProps extends SbBlokData {
  label: string;
  language: string;
  linkLabel: string;
}

export interface LanguageBannerItemProps {
  blok: LanguageBannerItemBlokProps;
  currentLanguage?: string;
}

const LanguageBannerItem = ({
  blok,
  currentLanguage,
}: LanguageBannerItemProps) => {
  const { alternateUrls = [] } = useContext(SSGDataContext);
  const { label, language, linkLabel } = blok;

  const shouldShow = () =>
    currentLanguage?.toLocaleLowerCase() ===
    language.split('-')[0].toLocaleLowerCase();

  const alternateUrl = alternateUrls.find(
    ({ hrefLang }) => hrefLang === language,
  );
  const href = `/${language}/${alternateUrl ? `${alternateUrl.slug}` : ''}`;

  return shouldShow() ? (
    <>
      <div className="text-sm font-normal lg:text-base text-GSbase/black">
        {label}
      </div>
      <div>
        <ButtonComponent
          className="!text-sm !font-normal lg:!text-base !text-GSbase/black !w-fit !py-0"
          label={linkLabel}
          link={{
            url: href,
            linktype: 'url',
            cached_url: '',
            fieldtype: '',
            id: blok._uid || '',
          }}
          hasRTicon={false}
          type="textLink"
        />
      </div>
    </>
  ) : null;
};

export default LanguageBannerItem;
