import { storyblokEditable, SbBlokData } from '@storyblok/react';

import FeatureCardComponent from '@/components/FeatureCard/FeatureCard';

export interface FeatureCardBlokProps extends SbBlokData {
  backgroundColor?: 'none' | 'GSpink-400' | 'GSgreen-300' | 'GSyellow-300';
  header: string;
  image: Asset;
  subheader: string;
  text: string;
}

interface FeatureCardProps {
  blok: FeatureCardBlokProps;
  headerColorVariant: 'GSpurple/500' | 'GSgreen/500' | 'GSorange/500';
  imageAsHeader?: boolean;
  theme?: 'default' | 'ideal' | '';
}

const FeatureCard = ({ blok, ...props }: FeatureCardProps) => (
  <div {...storyblokEditable(blok)} className="w-full h-full">
    <FeatureCardComponent {...blok} {...props} />
  </div>
);

export default FeatureCard;
