import cx from 'classnames';
import StoryblokImage from '../StoryblokImage/StoryblokImage';

export interface FeatureProps {
  backgroundColor?: 'none' | 'GSpink-400' | 'GSgreen-300' | 'GSyellow-300';
  header: string;
  headerColorVariant: 'GSpurple/500' | 'GSgreen/500' | 'GSorange/500';
  image: Asset;
  imageAsHeader?: boolean;
  subheader: string;
  text: string;
  theme?: 'default' | 'ideal' | '';
}

const FeatureCard = ({
  backgroundColor = 'none',
  header,
  headerColorVariant,
  image,
  imageAsHeader = false,
  subheader,
  text,
  theme = 'default',
}: FeatureProps) => {
  if (theme === 'ideal') {
    return (
      <div
        className={cx(
          'flex flex-col rounded-2xl h-full grow justify-between overflow-hidden',
          {
            'bg-GSpink/400': backgroundColor === 'GSpink-400',
            'bg-GSgreen/300': backgroundColor === 'GSgreen-300',
            'bg-GSyellow/300': backgroundColor === 'GSyellow-300',
          },
        )}
      >
        <div className="flex flex-col px-8 pt-8">
          <div className="flex flex-col gap-1 sm:gap-[6px] break-words">
            <div className="font-worksans text-GSbase/black font-bold text-[22px] leading-[30px] lg:text-[28px] lg:leading-10">
              {header}
            </div>
            <div className="font-raleway text-GSbase/black font-bold text-xl lg:text-[32px] lg:leading-10">
              {subheader}
            </div>
          </div>
          <div className="font-worksans font-normal text-sm leading-6 lg:text-xl">
            {text}
          </div>
        </div>
        <StoryblokImage blok={image} />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col w-full h-full grow bg-GSbase/white rounded border border-solid border-GSparchment/300 break-words shadow-lg
      p-4 gap-4
      sm:p-6 sm:gap-6
      lg:p-8 lg:gap-8"
    >
      {imageAsHeader && image && image.filename && (
        <StoryblokImage
          className="self-start max-w-[48px] max-h-[48px] sm:max-w-[56px] sm:max-h-[56px] lg:max-w-[64px] lg:max-h-[64px]"
          blok={image}
        />
      )}
      {!imageAsHeader && header && (
        <div
          className={cx('font-extrabold text-5xl sm:text-6xl lg:text-7xl', {
            'text-GSpurple/500': headerColorVariant === 'GSpurple/500',
            'text-GSgreen/500': headerColorVariant === 'GSgreen/500',
            'text-GSorange/500': headerColorVariant === 'GSorange/500',
          })}
        >
          {header}
        </div>
      )}
      {(subheader || text) && (
        <div className="flex flex-col w-full gap-2">
          {subheader && (
            <h3
              className="font-raleway text-GSblue/600 font-bold text-lg
              sm:text-xl
              lg:text-2xl"
            >
              {subheader}
            </h3>
          )}
          {text && (
            <div
              className="font-normal text-GSdeep/900 text-sm whitespace-pre-wrap
              sm:text-base
              lg:text-base"
            >
              {text}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FeatureCard;
