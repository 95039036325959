import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import { WebsiteMenuLinkBlokProps } from '@/blocks/WebsiteMenuLink/WebsiteMenuLink';
import WebsiteMenuSubmenu, {
  WebsiteMenuSubmenuBlokProps,
} from '../WebsiteMenuSubmenu/WebsiteMenuSubmenu';
import { DropdownTriangle } from '../../icons/DropdownTriangle';
import { LanguageSwitcherIcon } from '../WebsiteHeader/icons';
import { ArrowDownIcon } from './icons';

export interface WebsiteMenuDropdownBlokProps extends SbBlokData {
  title: string;
  isLanguageSwitcher?: boolean;
  items: Array<WebsiteMenuLinkBlokProps | WebsiteMenuSubmenuBlokProps>;
  isActive: boolean;
}

export interface WebsiteMenuDropdownProps {
  blok: WebsiteMenuDropdownBlokProps;
}

const WebsiteMenuDropdown = ({ blok }: WebsiteMenuDropdownProps) => {
  const { isActive, title, isLanguageSwitcher, items } = blok;
  const refDropdownButton = React.useRef<HTMLDivElement>(null);
  const refDropdownList = React.useRef<HTMLDivElement>(null);
  const [dropdownLeft, setDropdownLeft] = React.useState(0);
  const [dropdownRight, setDropdownRight] = React.useState<
    number | undefined
  >();
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeSubmenuItemId, setActiveSubmenuItemId] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    const dropdownButtonNode = refDropdownButton.current;
    const dropdownListNode = refDropdownList.current;

    if (dropdownButtonNode && dropdownListNode) {
      const { width: buttonWidth } = dropdownButtonNode.getBoundingClientRect();
      const { width: listWidth } = dropdownListNode.getBoundingClientRect();

      const offset = (buttonWidth - listWidth) / 2;
      setDropdownLeft(offset);
    }
  }, [items, refDropdownButton, refDropdownList]);

  React.useEffect(() => {
    const dropdownListNode = refDropdownList.current;
    if (dropdownListNode) {
      const { left, right } = dropdownListNode.getBoundingClientRect();
      if (left < 0) {
        setDropdownLeft(0);
      }

      if (right > window.innerWidth) {
        setDropdownRight(0);
      }
    }
  }, [dropdownLeft]);

  return (
    <>
      <div
        className="relative text-black hidden lg:block group/dropdown"
        {...storyblokEditable(blok)}
      >
        {isLanguageSwitcher ? (
          <div className="flex text-GSdeep/500 text-xl items-center gap-x-4 rounded box-border border border-transparent">
            <LanguageSwitcherIcon className="w-14 h-8" />
          </div>
        ) : (
          <div
            ref={refDropdownButton}
            className="cursor-pointer
            flex text-GSdeep/500 pl-4 lg:py-2 lg:px-2 xl:px-4 items-center gap-x-4 rounded box-border border border-transparent
            hover:border hover:border-GSparchment/400 hover:bg-GSparchment/200 hover:text-GSblue/600
            group-hover/dropdown:border group-hover/dropdown:border-GSparchment/400 group-hover/dropdown:bg-GSparchment/200"
          >
            <div
              className={cx('lg:text-xl', {
                'font-medium': isActive,
                'hover:font-normal': !isActive,
              })}
            >
              {title}
            </div>
            <div className="group-hover/dropdown:rotate-180">
              <ArrowDownIcon className="stroke-GSdeep/500 group-hover:stroke-GSblue/600" />
            </div>
          </div>
        )}
        <div
          className="absolute invisible group-hover/dropdown:flex group-hover/dropdown:visible"
          ref={refDropdownList}
          style={{
            left:
              isLanguageSwitcher || dropdownRight !== undefined
                ? 'auto'
                : dropdownLeft,
            right: isLanguageSwitcher ? '0' : dropdownRight,
          }}
          onMouseOut={() => setActiveSubmenuItemId(undefined)}
          onBlur={() => setActiveSubmenuItemId(undefined)}
        >
          <div
            className="flex flex-col pt-4"
            style={{ position: '-webkit-sticky' }}
          >
            <div className="min-w-max mt-[-1px] box-border border border-GSparchment/400 rounded-[3px] bg-GSbase/white shadow-sm flex text-GSdeep/500 text-lg p-4">
              <div className="shrink-0">
                {items?.map((item) => (
                  <div
                    key={item._uid}
                    onMouseOver={() => setActiveSubmenuItemId(item._uid)}
                    onFocus={() => setActiveSubmenuItemId(item._uid)}
                  >
                    <StoryblokComponent
                      blok={item}
                      className={cx(
                        'font-normal py-2 px-4 hover:bg-GSparchment/200',
                        {
                          'font-medium': item.isActive,
                        },
                      )}
                      labelOnly
                    />
                  </div>
                ))}
              </div>
              <>
                {items?.map((item) => {
                  if (item.component !== 'websiteMenuSubmenu') {
                    return null;
                  }

                  return (
                    <div
                      className={cx('pl-4 gap-4 flex', {
                        hidden: activeSubmenuItemId !== item._uid,
                        'group-hover/dropdown:visible':
                          activeSubmenuItemId === item._uid,
                      })}
                      key={item._uid}
                      onMouseOver={() => setActiveSubmenuItemId(item._uid)}
                      onFocus={() => setActiveSubmenuItemId(item._uid)}
                    >
                      <div className="bg-GSparchment/300 w-[1px] h-full" />
                      <WebsiteMenuSubmenu blok={item} itemsOnly desktop />
                    </div>
                  );
                })}
              </>
            </div>
          </div>
        </div>
        <div className="absolute invisible group-hover/dropdown:visible min-w-full flex justify-center">
          <div className="mt-[-8px] pt-4 self-center z-10">
            <DropdownTriangle />
          </div>
        </div>
      </div>

      <div
        {...storyblokEditable(blok)}
        className="relative flex flex-col items-center gap-x-4 lg:hidden"
      >
        <div
          className={cx(
            'select-none group flex cursor-pointer justify-between w-full pl-4 pr-3 py-2 sm:pl-6 sm:pr-5 sm:py-4 box-border border-y border-transparent hover:border-y hover:border-GSparchment/400 hover:bg-GSparchment/200 hover:text-GSblue/600',
            {
              'bg-GSparchment/200 text-GSblue/500 border-b border-t border-solid !border-GSparchment/400 font-medium':
                isOpen,
              'text-GSdeep/500': !isOpen,
            },
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="text-base sm:text-lg">{title}</div>
          <div
            className={cx('flex items-center ui-open:transform', {
              'rotate-180': isOpen,
            })}
          >
            <ArrowDownIcon
              className={cx('stroke-GSdeep/500 group-hover:stroke-GSblue/600', {
                '!stroke-GSdeep/500': !isOpen,
                '!stroke-GSblue/500 ': isOpen,
              })}
            />
          </div>
        </div>
        <div
          className={cx('flex flex-col w-full', {
            hidden: !isOpen,
            block: isOpen,
          })}
        >
          {items?.map((item) => (
            <StoryblokComponent blok={item} key={item._uid} level={1} />
          ))}
        </div>
      </div>
    </>
  );
};

export default WebsiteMenuDropdown;
