import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { useEffect, useState } from 'react';

import PageContainer from '../../components/PageContainer/PageContainer';
import { LanguageBannerItemBlokProps } from '../LanguageBannerItem/LanguageBannerItem';

export interface LanguageBannerBlokProps extends SbBlokData {
  content: Array<LanguageBannerItemBlokProps>;
}

export interface LanguageBannerProps {
  blok: LanguageBannerBlokProps;
}

const LanguageBanner = ({ blok }: LanguageBannerProps) => {
  const { content } = blok;

  const [showLanguageBanner, setShowLanguageBanner] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0];
    const documentLanguage = document.documentElement.lang;
    const languageSupported = content.find(
      (item) => item.language.split('-')[0] === browserLanguage,
    );

    if (
      languageSupported &&
      browserLanguage &&
      documentLanguage &&
      browserLanguage.toLocaleLowerCase() !==
        documentLanguage.toLocaleLowerCase()
    ) {
      setCurrentLanguage(browserLanguage);
      setShowLanguageBanner(true);
    }
  }, []);

  if (!showLanguageBanner) {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className="bg-GSblue/100 text-GSdeep/900"
    >
      <PageContainer className="!py-4 sm:!py-4">
        <div className="flex flex-row gap-x-2 w-full sm:gap-x-6">
          <div className="flex flex-col gap-2 sm:items-center sm:flex-row sm:gap-4">
            {content.map((nestedBlok) => (
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                currentLanguage={currentLanguage}
              />
            ))}
          </div>
          <button
            aria-label="Close"
            type="button"
            onClick={() => setShowLanguageBanner(false)}
            className="self-start ml-auto sm:self-center"
            placeholder="button"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8558 4.85364C16.0511 4.65838 16.0511 4.3418 15.8558 4.14654C15.6606 3.95128 15.344 3.95128 15.1487 4.14654L9.98674 9.30853L4.85349 4.17528C4.65823 3.98002 4.34165 3.98002 4.14639 4.17528C3.95112 4.37054 3.95112 4.68712 4.14639 4.88239L9.27963 10.0156L4.17497 15.1203C3.9797 15.3156 3.9797 15.6321 4.17497 15.8274C4.37023 16.0227 4.68681 16.0227 4.88207 15.8274L9.98674 10.7227L15.1202 15.8562C15.3154 16.0514 15.632 16.0514 15.8273 15.8562C16.0225 15.6609 16.0225 15.3443 15.8273 15.149L10.6938 10.0156L15.8558 4.85364Z"
                fill="#010911"
              />
            </svg>
          </button>
        </div>
      </PageContainer>
    </section>
  );
};

export default LanguageBanner;
