import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

export interface WebsiteMenuLinkBlokProps extends SbBlokData {
  label: string;
  link: Link;
  hideOnDesktop: boolean;
  isActive: boolean;
  image?: Asset;
}

export interface WebsiteMenuLinkProps {
  blok: WebsiteMenuLinkBlokProps;
  level?: number;
  submenuItem?: boolean;
}

const WebsiteMenuLink = ({
  blok,
  level = 0,
  submenuItem,
}: WebsiteMenuLinkProps) => {
  const { isActive, label, link, hideOnDesktop } = blok;

  return (
    <StoryblokLink
      {...storyblokEditable(blok)}
      testId={blok._uid}
      link={link}
      className={cx(
        'block content-center',
        'text-base sm:text-lg',
        'text-GSdeep/500 hover:text-GSblue/600',
        {
          'font-medium': isActive,
          'hover:font-normal': !isActive,
          'lg:hidden': hideOnDesktop,
          hidden: isEmptyLink(link),
          'pl-4 sm:pl-6 pr-3 py-2 sm:pr-5 sm:py-4': level === 0,
          'px-6 sm:px-10 py-2 sm:py-4 underline': level === 1,
          'px-4 py-2 underline': level === 2,
          'lg:text-lg lg:hover:underline lg:p-[10px]':
            submenuItem && level === 0,
          'lg:text-xl lg:py-2 lg:px-2 xl:px-4': !submenuItem,
          'hover:border-y hover:border-GSparchment/400 hover:bg-GSparchment/200 lg:hover:border-x':
            !submenuItem,
          'rounded box-border border-y border-transparent lg:border-x':
            !submenuItem,
        },
      )}
    >
      {label}
    </StoryblokLink>
  );
};

export default WebsiteMenuLink;
